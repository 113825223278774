<template>
  <div>
    <div class="m-5 bg-white">
      <div class="border border-gray-400">
        <div class="p-4 flex justify-between items-center">
          <span class="font-bold text-rose">
            {{ $t('reports_nurse_title') }}
          </span>
          <BackFa :text="$t('back')" />
        </div>

        <BaseTable
          class="text-right mb-6"
          header-class="bg-gray-50 text-gray-400 text-sm"
          :headers="headers"
          :items="items"
          theme-type="even"
        >
          <template #item:docter="{ item }">
            <div class="flex items-center">
              <div
                :class="{ 'ml-2': isRtl, 'mr-2': isLtr }"
                class="bg-purple-400 h-10 w-10 rounded-lg"
              ></div>
              <div class="font-extralight">
                <h3 class="text-base">{{ item.docter }}</h3>
                <h3 class="text-xs font-light text-gray-600">
                  {{ item.specialty }}
                </h3>
              </div>
            </div>
          </template>
          <template #item:document="{ item }">
            <img width="32" class="mx-auto" :src="item.document" alt="" />
          </template>
          <template #item:allergy="{ item }">
            <button
              @click="goto(item.to)"
              class="bg-blue-300 text-white px-6 py-1 rounded-2xl shadow-lg"
            >
              {{ item.allergy }}
            </button>
          </template>
          <template #item:image>
            <i class="text-2xl text-gray-400 fal fa-file-download"></i>
          </template>
        </BaseTable>
      </div>
    </div>
  </div>
</template>

<script>
import BaseTable from '@/components/BaseTable'
import BackFa from '../components/back/BackFa.vue'

export default {
  name: 'ReportsNurse',
  components: { BaseTable, BackFa },
  computed: {
    headers() {
      return [
        {
          key: 'docter',
          text: `${this.$t('doctor_reports')}/${this.$t('specialty_reports')}`,
        },
        {
          key: 'date',
          text: this.$t('date_reports'),
        },
        {
          key: 'subject',
          text: this.$t('subject_reports2'),
        },
        {
          key: 'allergy',
          text: this.$t('allergy_reports'),
        },
        {
          key: 'image',
          text: '',
          align: 'center',
        },
      ]
    },
    items() {
      return [
        {
          date: this.convertDate('2021-1-1'),
          specialty: this.$t('name_nurse_reports'),
          docter: this.$t('name_main_page_list'),
          subject: this.$t('subject_text_repors'),
          document: require('../assets/images/theme/edit-icon.png'),
          allergy: this.$t('message_reports'),
        },
        {
          date: this.convertDate('2021-1-5'),
          specialty: this.$t('specialty_reports_nurse'),
          docter: this.$t('name_doctor_reports4'),
          subject: this.$t('subject_text_repors'),
          document: require('../assets/images/theme/edit-icon.png'),
          allergy: this.$t('message_reports_nurse2'),
        },
        {
          date: this.convertDate('2021-1-5'),
          specialty: this.$t('specialty_reports_nurse2'),
          docter: this.$t('name2_main_page_list'),
          subject: this.$t('subject_text_repors'),
          document: require('../assets/images/theme/edit-icon.png'),
          allergy: this.$t('message_reports_nurse'),
          to: { name: 'ReportsNurse2' },
        },
      ]
    },
  },
  data() {
    return {
      ShowInformation: false,
    }
  },
  methods: {
    goto(to) {
      !!to && this.$router.push(to)
    },
  },
}
</script>

<style></style>
